<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>DTM Collections</b-card-title>
      <b-dropdown
        :text="'Lust ' + year"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item v-for="day in years" :key="day" @click="postyear(day)">
          {{ day }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="300"
        class="my-1"
        :options="sessionsByDeviceDonut.chartOptions"
        :series="sessionsByDeviceDonut.series"
      />

      <!-- chart info -->
      <div
        v-for="(data, index) in chartData.chartInfo"
        :key="data.name"
        class="d-flex justify-content-between"
        :class="index === chartData.chartInfo.length - 1 ? 'mb-0' : 'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon :icon="data.icon" size="16" :class="data.iconColor" />
          <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span
          ><span>- {{ data.usage }}%</span>
        </div>
        <div>
          <span>{{ data.upDown }}%</span>
          <feather-icon
            :icon="data.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
            :class="data.upDown > 0 ? 'text-success' : 'text-danger'"
            class="mb-25 ml-25"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  data() {
    return {
      resdata: {},
      year: 2020,
      years: [],
      chartData: {},
      sessionsByDeviceDonut: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["REG", "DTM", "DONATE"],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
    };
  },
  methods: {
    getyears() {
      const that = this;
      let now = new Date().getFullYear();
      var i = 2020;
      for (i = 2020; i <= now; i++) {
        that.years.push(i);
      }
    },
    postyear(year) {
      this.year = year;
      this.$http
        .get(`/dtm/desh/report/${year || 2021}/paichart/`)
        .then((response) => {
          this.resdata = response.data;
          this.sessionsByDeviceDonut.series = response.data.data;
        });
    },
  },
  created() {
    this.year = new Date().getFullYear();
    this.getyears();
    // Subscribers gained
    this.$http
      .get(`/dtm/desh/report/${this.year || 2021}/paichart/`)
      .then((response) => {
        this.resdata = response.data;
        this.sessionsByDeviceDonut.series = response.data.data;
      });
  },
};
</script>
