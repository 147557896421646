<template>
  <div v-if="is_sup">
    <div class="text-center mb-5 mt-3" v-if="load">
      <b-spinner variant="primary" label="Loading..." />
      <b>Loading...</b>
    </div>
    <b-overlay
      v-else
      :show="load"
      variant="transparent"
      opacity="1.0"
      blur="5px"
      rounded="sm"
      no-fade
    >
      <!-- Stats Card Horizontal -->
      <b-row class="align-middle">
        <b-col
          lg="3"
          sm="6"
          v-for="(data, index) in resdata.accounts"
          :key="index"
        >
          <statistic-card-horizontal
            icon="ServerIcon"
            :statistic="data.balance + '৳'"
            :statistic-title="data.accountName"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            color="danger"
            icon="CreditCardIcon"
            :statistic="resdata.in_total + '৳'"
            statistic-title="In Total"
          />
        </b-col>
      </b-row>

      <!-- Stats Card Vertical -->
      <b-row class="match-height">
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="UserPlusIcon"
            :statistic="resdata.count.registed || 0"
            statistic-title="Registed"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="warning"
            icon="UserXIcon"
            :statistic="resdata.count.unpaid || 0"
            statistic-title="Unpaid"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="success"
            icon="UserCheckIcon"
            :statistic="resdata.count.dtm || 0"
            statistic-title="DTM"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="success"
            icon="EyeIcon"
            :statistic="resdata.count.active || 0"
            statistic-title="Active"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            color="danger"
            icon="EyeOffIcon"
            :statistic="resdata.count.inactive || 0"
            statistic-title="Inactive"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="primary"
            icon="DatabaseIcon"
            :statistic="resdata.count.transactions || 0"
            statistic-title="Transactions"
          />
        </b-col>
        <!-- new line  -->
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="danger"
            icon="AlertTriangleIcon"
            :statistic="resdata.count.irregular || 0"
            statistic-title="IRREGULAR DTM"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="warning"
            icon="AlertOctagonIcon"
            :statistic="resdata.count.due_count || 0"
            statistic-title="Panding Payment DTM"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="success"
            icon="CheckIcon"
            :statistic="resdata.count.fullpay_count || 0"
            statistic-title="Full Payment DTM"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="danger"
            icon="DollarSignIcon"
            :statistic="'৳' + resdata.count.total_due || 0"
            :statistic-title="'Total DTM Due (' + resdata.count.due_count + ')'"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="warning"
            icon="DollarSignIcon"
            :statistic="'৳' + resdata.count.total_adv || 0"
            :statistic-title="
              'Total DTM Advance (' + resdata.count.adv_count + ')'
            "
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            hide-chart
            color="success"
            icon="DollarSignIcon"
            :statistic="'৳' + resdata.count.total_pay || 0"
            statistic-title="Total DTM Payment"
          />
        </b-col>
      </b-row>

      <div class="row">
        <div class="col-12 col-lg-6">
          <d-chart />
        </div>
        <div class="col-12 col-lg-6">
          <d-chart2 />
        </div>
        <div class="col-12 col-lg-6">
          <d-ratio />
        </div>
        <div class="col-12 col-lg-6">
          <d-ratio2 />
        </div>
      </div>
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> Statics </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.reg_count"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Registration FEE </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.reg_fee"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Transaction FEE </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.dtm_fee"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Methods Transaction FEE </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.method_fee"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Donation For </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.donate_for"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Sundy Transaction</b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.sundy_fee"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> DTM Debit Transaction</b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields"
            :items="resdata.debit"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(today)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(month)="data">
              {{ "৳" + data.value }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(year)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(total)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
      <!-- only text-->
      <b-card class="position-static">
        <blockquote class="blockquote mb-0">
          <b> Upcoming Food Sponsor </b>
          <footer class="blockquote-footer">
            <small class="text-muted">
              Youth for Bangladesh
              <cite title="Source Title">DTM</cite>
            </small>
          </footer>
        </blockquote>
        <!-- new table start  -->

        <div>
          <b-table
            small
            :fields="fields2"
            :items="resdata.sponsor"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(total_amount)="data">
              {{ "৳" + data.value }}
            </template>

            <!-- A virtual composite column -->
            <template #cell(status)="data">
              <b-badge class="mr-1" variant="success" v-if="data.value">
                <feather-icon icon="GitBranchIcon" class="mr-25" />
                <span> Paid </span>
              </b-badge>
              <b-badge class="mr-1" variant="danger" v-else>
                <feather-icon icon="GitPullRequestIcon" class="mr-25" />
                <span>Unpaid</span>
              </b-badge>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>

        <!-- nwe table end  -->
      </b-card>
      <!-- end table  -->
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BBadge,
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import { kFormatter } from "@core/utils/filter";
import DChart from "./chart.vue";
import DChart2 from "./CountChart.vue";
import DRatio from "./Ratio.vue";
import DRatio2 from "./CountRatio.vue";
// import CardStatisticOrderChart from './CardStatisticOrderChart.vue'
// import CardStatisticProfitChart from './CardStatisticProfitChart.vue'
// import CardStatisticsGroup from './CardStatisticsGroup.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BProgress,
    BBadge,
    BCardGroup,
    BCard,
    BCardText,
    BOverlay,
    BSpinner,
    DChart,
    DChart2,
    DRatio,
    DRatio2,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    // CardStatisticOrderChart,
    // CardStatisticProfitChart,
    // CardStatisticsGroup,
  },
  data() {
    return {
      load: true,
      is_sup: false,
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},
      resdata: {
        in_total: 0,
        count: {
          registed: 0,
          unpaid: 0,
          dtm: 0,
          active: 0,
          inactive: 0,
          transactions: 0,
          total_due: 0,
          total_adv: 0,
          total_pay: 0,
          irregular: 0,
        },
        accounts: [],
      },

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "name", label: "Name" },
        // A regular column
        { key: "today", label: "Today" },
        // A regular column
        { key: "month", label: "This Month" },
        // A virtual column made up from two fields
        { key: "year", label: "This Year" },
        { key: "total", label: "Total" },
      ],
      fields2: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "event_date", label: "Event Date" },
        { key: "fullName", label: "Name" },
        { key: "dtm_id", label: "DTM ID" },
        { key: "mobile", label: "Mobile Number" },
        { key: "event_area", label: "Event Area" },
        { key: "item", label: "Item" },
        { key: "packet", label: "Meal" },
        { key: "total_amount", label: "Total Amount" },
        { key: "status", label: "Payment Status" },
      ],
      items: [
        {
          name: { first: "Fitbit", last: "Activity Tracker" },
          Category: "Fitness",
          order_status: { status: "Delivered", variant: "success" },
          Popularity: { value: 50, variant: "success" },
          price: 99.99,
        },
        {
          name: { first: "Fitbit ", last: "Flex Wireless Activity" },
          Category: "Fitness",
          order_status: { status: "Pending", variant: "primary" },
          Popularity: { value: 36, variant: "primary" },
          price: 89.85,
        },
        {
          name: { first: "Fitbit", last: "Sleep Tracker Wristband" },
          Category: "Fitness",
          order_status: { status: "Delivered", variant: "success" },
          Popularity: { value: 76, variant: "success" },
          price: 65.25,
        },
        {
          name: { first: "Fitbit", last: "Sleep Wristband" },
          Category: "Fitness",
          order_status: { status: "On Hold", variant: "warning" },
          Popularity: { value: 55, variant: "warning" },
          price: 75.55,
        },
        {
          name: {
            first: "Fitbit",
            last: "Clip for Zip Wireless Activity Trackers",
          },
          Category: "Fitness",
          order_status: { status: "Canceled", variant: "danger" },
          Popularity: { value: 75, variant: "danger" },
          price: 105.55,
        },
      ],
    };
  },
  created() {
    // Subscribers gained
    this.$http.get("/dtm/desh/report/all/data/").then((response) => {
      this.resdata = response.data;
      this.load = false;
    });
    // this.getdata(0);
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
  watch: {
  },
  methods: {
    kFormatter,
    getdata(counter) {
      this.$http.get("/dtm/desh/report/all/data/").then((response) => {
        awaitthis.resdata = response.data;
      });
      getdata()
      // while (counter < 100) {
      //   console.log(counter);
        
      //     counter++;
      //   setInterval( function() {
      //   console.log( 'Test' );
      // }, 100)
      // }
    },
  },
};
</script>
