<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25"> Yearly DTM Collections </b-card-title>
        <b-card-text class="mb-0">
          {{ resdata.year || 2020 }} Total Collections:
          {{ resdata.total || 0 }} BDT.
        </b-card-text>
      </div>
      <!-- primary -->
      <b-dropdown
        id="dropdown-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :text="resdata.year"
        variant="flat-primary"
        class="text-muted cursor-pointer"
      >
        <b-dropdown-item
          v-for="(value, index) in years"
          :key="index"
          @click="postyear(value)"
          >{{ value }}</b-dropdown-item
        >
      </b-dropdown>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="salesLine.chartOptions"
        :series="salesLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueApexCharts,
    BCard,
    BBadge,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      year: 2021,
      years: [],
      resdata: {
        year: "2021",
        total: 6,
        data: [],
      },
      salesLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2", "#ff0066", "#ff9966", "#33cccc"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "July",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    getyears() {
      const that = this;
      let now = new Date().getFullYear();
      var i = 2020;
      for (i = 2020; i <= now; i++) {
        that.years.push(i);
      }
    },
    postyear(year) {
      this.year = year;
      this.$http
        .get(`/dtm/desh/report/${year || 2021}/chart/`)
        .then((response) => {
          this.resdata = response.data;
          this.salesLine.series = response.data.data;
        });
    },
  },
  created() {
    this.year = new Date().getFullYear();
    this.getyears();
    // Subscribers gained
    this.$http
      .get(`/dtm/desh/report/${this.year || 2021}/chart/`)
      .then((response) => {
        this.resdata = response.data;
        this.salesLine.series = response.data.data;
      });
  },
};
</script>
